/* eslint-disable linebreak-style */
import React, { useEffect } from 'react';
import { VscArrowRight } from 'react-icons/vsc';
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import '../../components/sharedStyle.css';
import './Contact.css';
import { commonClassNames, styles } from '../../utils/theme/styles';

function Contacts() {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const inputStyle =
    'rounded-lg w-full lg:w-[312.79px] h-[60px] pl-4 focus:outline-none shadow';
  const contactStyle = 'tagText font-medium text-[13.86px] text-[#979897]';
  const contactCard = 'bg-white w-full lg:w-1/4 px-8 py-12';
  const contactCardHeader = 'text-[19.5px] font-extrabold text-[#88000F] mb-6';
  const mapUrl =
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3961.992760695788!2d39.2186309140971!3d-6.770733968091875!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c4f269aa52ae9%3A0xe875f348d72724f5!2sSKYCITY%20MALL!5e0!3m2!1sen!2stz!4v1674728509809!5m2!1sen!2stz';
  return (
    <div className="">
      {/* banner  */}
      <div className="contact-bg w-full lg:h-[55vh] md:h-[50vh] sm:h-[40vh] bg-no-repeat bg-cover">
        <div className={commonClassNames('lg:h-[55vh] md:h-[50vh] sm:h-[40vh]').transparentImageForegroundContentContainerClass} style={styles.transparentBackgroundImage}>
          <div className={commonClassNames().transparentImageForegroundContentClass}>
            <h1 className={commonClassNames().transparentImageForegroundTitle}>
              Get in Touch
              <br />
              let&apos;s talk.
            </h1>
          </div>
        </div>
      </div>
      {/* test screen */}
      {/* <div className="h-60 xl:bg-green-500 ">test screen</div> */}
      <div className="lg:px-12 sm:px-6 md:px-8 bg-[#F8F8F8] lg:grid lg:justify-center lg:py-[100px] md:py-[80px] sm:py-[50px]">
        <span className={"headingText text-[#88000F] font-bold md:text-[40px] lg:text-[45px] mb-[20px] px-0"}>
          Our head Office.
        </span>
        <div className="lg:flex grid gap-1">
          <div className="">
            <div className="grid gap-6">
              <div className={`${contactStyle}`}>
                <p>8th Floor,</p>
                <p>Skycity Mall,</p>
                <p>Plot 403/1-2,</p>
                <p>Block A,</p>
                <p>P. O. Box 4756,</p>
                <p>Mlalakuwa, University Road - Dar es Salaam,</p>
                <p>Tanzania</p>
              </div>
              <div className={`${contactStyle}`}>
                <p>Email: info@derm.co.tz</p>
                <p>Tel: +255 678 787 787</p>
                <p> Mobile: +255 678 787 787</p>
                <p>info@derm.co.tz</p>
              </div>
            </div>
          </div>
          <div className="">
            <form className="grid gap-3  md:px-12 lg:px-12  ">
              <div className="grid gap-6">
                <div className="grid md:flex lg:flex gap-6 ">
                  <input
                    type="text"
                    name="user_name"
                    className={`${inputStyle}`}
                    placeholder="Your Full Name"
                    required
                  />
                  <input
                    type="email"
                    name="user_email"
                    className={`${inputStyle}`}
                    placeholder="Your Email Address"
                    required
                  />
                </div>
                <div className="grid md:flex lg:flex gap-6 ">
                  <input
                    type="tel"
                    name="user_name"
                    className={`${inputStyle}`}
                    placeholder="Your Phone Number"
                    required
                  />
                  <input
                    type="email"
                    name="user_email"
                    className={`${inputStyle}`}
                    placeholder="Your Address"
                    required
                  />
                </div>
                <div className=""> </div>

                <div className="mb-4">
                  <textarea
                    name="message"
                    placeholder="Enter Your Message Here"
                    className="rounded-lg w-full lg:w-[655.37px] h-[132.81px] md:h-32 lg:h-32 py-3 pl-4 focus:outline-none shadow"
                    required
                  />
                </div>
                <div className="">
                  <button
                    type="submit"
                    className="bg-[#88000F] text-white md:w-1/4 w-[195.31px] h-[55.55px]  cursor-pointer"
                  >
                    <div className="flex gap-2 items-center justify-center">
                      <p className="tagText text-[13.89px]">Submit Message</p>
                      <VscArrowRight />
                    </div>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* companies contact */}
      <div className="py-6 lg:py-0 bg-[#F8F8F8] pt-[50px">
        <h1 className={commonClassNames().titleClass}>
          Our Companies Contacts
        </h1>
      </div>
      <div className="px-6 lg:px-16 xl:px-16 2xl:px-64 lg:pt-16 lg:pb-[300px] bg-[#F8F8F8] grid gap-6 lg:flex">
        <div className={`${contactCard}`}>
          <p className={`${contactCardHeader}`}>Derm Group (T) Ltd</p>
          <div className="grid gap-6">
            <div className={`${contactStyle}`}>
              <p>8th Floor,</p>
              <p>Skycity Mall,</p>
              <p>Plot 403/1-2,</p>
              <p>Block A,</p>
              <p>P. O. Box 4756,</p>
              <p>Mlalakuwa, University Road - Dar es Salaam,</p>
              <p>Tanzania</p>
            </div>
            <div className={`${contactStyle}`}>
              <p>Email: info@derm.co.tz</p>
              <p>Tel: +255 678 787 787</p>
              <p> Mobile: +255 678 787 787</p>
              <p>Website: www.derm.co.tz</p>
            </div>
          </div>
        </div>
        <div className={`${contactCard}`}>
          <p className={`${contactCardHeader}`}>Skyline Properties Limited</p>
          <div className="grid gap-6">
            <div className={`${contactStyle}`}>
              <p>11th Floor,</p>
              <p>Skycity Mall,</p>
              <p>Plot 403/1&2, Block A</p>
              <p>Block A,</p>
              <p>P. O. Box 4756,</p>
              <p>Mlalakuwa, University Road - Dar es Salaam,</p>
              <p>Tanzania</p>
            </div>
            <div className={`${contactStyle}`}>
              <p>Email: info@skylineproperties.co.tz</p>
              <p>Tel: +255-222 774-507</p>
              <p> Mobile: +255-222 774-507</p>
              <p>Website: www.derm.co.tz/skyline</p>
            </div>
          </div>
        </div>
        <div className={`${contactCard}`}>
          <p className={`${contactCardHeader}`}>Coast Concrete Poles</p>
          <div className="grid gap-6">
            <div className={`${contactStyle}`}>
              <p>11th Floor,</p>
              <p>Skycity Mall,</p>
              <p>Plot 403/1-2,</p>
              <p>Block A,</p>
              <p>P. O. Box 4756,</p>
              <p>Mlalakuwa, University Road - Dar es Salaam,</p>
              <p>Tanzania</p>
            </div>
            <div className={`${contactStyle}`}>
              <p>Email: ccp@dermtz.com</p>
              <p>Tel: +255 678 787 787</p>
              <p> Mobile: +255 678 787 787</p>
              <p></p>
            </div>
          </div>
        </div>
        <div className={`${contactCard}`}>
          <p className={`${contactCardHeader}`}>Derm Elevators Limited</p>
          <div className="grid gap-6">
            <div className={`${contactStyle}`}>
              <p>11th Floor,</p>
              <p>Skycity Mall,</p>
              <p>Plot 403/1-2,</p>
              <p>Block A,</p>
              <p>P. O. Box 4756,</p>
              <p>Mlalakuwa, University Road - Dar es Salaam,</p>
              <p>Tanzania</p>
            </div>
            <div className={`${contactStyle}`}>
              <p>Email: elevators@derm.co.tz</p>
              <p>Tel: +255-222 774-507</p>
              <p> Mobile: +255-222 774-507</p>
              <p>Website: www.derm.co.tz</p>
            </div>
          </div>
        </div>
      </div>
      {/* google map */}
      <div className='w-[100vw] items-center justify-center content-center py-[30px] lg:px-[20vw] md:px-[5vw] sm:px-0 relative lg:top-[-200px] md:top-[-50px]' style={{ zIndex: 10 }}>
        <div className="grid bg-[#F8F8F8] lg:h-[400px] md:h-[100px] px-6 lg:px-0 rounded-[10px]">
          <iframe
            title="This is location for Derm group"
            className="w-full h-[400px]"
            src={mapUrl}
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>

      {/* our companies */}
      <div className='relative top-[-80px]'>
        <OurCompanies />
      </div>
    </div>
  );
}

export default Contacts;
