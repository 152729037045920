/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import PropTypes from 'prop-types'
import './Stories.css';
import { commonClassNames, styles } from '../../utils/theme/styles';
import { connect } from 'react-redux';
import { Editor, EditorState, convertFromRaw } from 'draft-js';

function FullStory({
  selectedStory
}) {

  const [fullStory, setFullStory] = useState({
    isRaw: true,
    es: null
  })

  const convertContentToEditorState = (content) => {
    if (content) {
      try {
        const es = EditorState.createWithContent(
          convertFromRaw(JSON.parse(content))
        )
        setFullStory({ ...fullStory, isRaw: false, es })
      } catch {
        setFullStory({ ...fullStory, isRaw: true })
      }
    } else {
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    convertContentToEditorState(selectedStory.story_description)
  }, [])

  return (
    <div className="">
      {/* banner  */}
      <div className="relative w-full lg:h-[50vh] md:h-[40vh] sm:h-[30vh] bg-no-repeat bg-cover"
        style={{
          backgroundImage: `url(${selectedStory.story_image})`
        }}
      >
        <div className={commonClassNames('lg:h-[50vh] md:h-[40vh] sm:h-[30vh]').transparentImageForegroundContentContainerClass}
          style={styles.transparentBackgroundImage}>
          <div className={'sm:mx-[6%] sm:pt-[8vh] lg:mx-[10%] lg:pt-[15vh]'}>
            <h3 style={{
              fontSize: 20,
              color: '#9FA09F',
              fontWeight: '600',
              marginBottom: '10px'
            }}>{selectedStory?.story_date}</h3>
            <h1 className={'sm:text-[18px] lg:text-[40px] font-500 text-white top-transparent-image-foreground-title'}>
              {selectedStory.story_title}
            </h1>
          </div>
        </div>
      </div>
      {/* stories */}
      <div className="bg-[#F8F8F8] md:px-[10%] sm:px-[5%] lg:pt-[60px] md:pt-[40px] sm:pt-[30px] text-[20px]">
        {
          !fullStory.isRaw && <Editor readOnly={true}
            editorState={fullStory.es} />
        }
        {
          fullStory.isRaw && <pre className='w-[100%] whitespace-pre-wrap font-sans'>{selectedStory?.story_description}</pre>
        }
      </div>
      <div className="bg-[#F8F8F8] px-[10%] py-[80px] mb-[80px] text-[20px]">
        <img src={selectedStory.story_description_image} className='cover-image-fit w-full lg:h-[600px] sm:h-[300px] rounded-2xl rounded-br-2xl' />
      </div>
      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

FullStory.propTypes = {
  selectedStory: PropTypes.object
}

const mapStateToProps = ({ app: { selectedStory } }) => ({
  selectedStory
})

export default connect(mapStateToProps, () => ({}))(FullStory);
