import Home from "../pages/home/Home";
import About from "../pages/about/About";
import Contact from "../pages/contacts/Contact";
import Careers from "../pages/careers/Careers";
import Stories from "../pages/stories/Stories";
import Test from "../pages/Test";
import FullStory from "../pages/stories/full-story";
import Staff from "../pages/about/staff";
import Gallery from "../pages/gallery/";
import HSE from "../pages/hse";
import StaffLink from '../pages/links'
import Management from "../pages/about/management";

const routes = [
    {
        path: '/',
        component: Home,
        exact: 'true'
    },
    {
        path: '/about/',
        component: About,
    },
    {
        path: '/management/',
        component: Management,
    },
    {
        path: '/staff/:id/',
        component: Staff,
    },
    {
        path: '/contact/',
        component: Contact,
    },
    {
        path: '/careers/',
        component: Careers,
    },
    {
        path: '/stories/',
        component: Stories,
        exact: 'true'
    },
    {
        path: '/stories/:id/',
        component: FullStory,
    },
    {
        path: '/gallery/',
        component: Gallery,
    },
    {
        path: '/hse/',
        component: HSE,
        exact: 'true'
    },
    {
        path: '/lnk/',
        component: StaffLink,
        exact: 'true'
    },
    {
        path: '/test/',
        component: Test,
    },
]

export default routes;
