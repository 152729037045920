/* eslint-disable linebreak-style */
import React from 'react';
// import { Link } from 'react-router-dom';
import govImg from '../../assets/images/gov.png';
import pssfImg from '../../assets/images/pssf.png';
import mwangaImg from '../../assets/images/mwanga.png';
import tanescoImg from '../../assets/images/tanesco.png';
import tigoImg from '../../assets/images/tigo.png';
import vodaImg from '../../assets/images/voda.png';
import nhcImg from '../../assets/images/nhc.png';
import '../sharedStyle.css';

function OurPartners() {
  const tagText =
    'tagText font-normal lg:font-semibold text-[18px] lg:text-[22px]  ';
  const partnerImgContainer = 'grid place-content-center items-center partner-logo mx-[6px] px-[10px]';
  const partnerImg = `contain-image-fit`
  // function myFunction() {
  //   window.location.replace('https://www.w3schools.com');
  // }

  return (
    <div className="py-[10px] bg-[#FFFFFF]">
      <div className='px-[10%]'>
        <span className={`${tagText} text-[#88000F]`}>
          WE ARE BEST IN BUSINESS
        </span>
      </div>
      <div style={{ marginTop: '20px' }} className='px-[10%]'>
        <span className={"headingText text-[#88000F] font-bold text-[30px] lg:text-[40px]"}>
          Our Clients
          <br /> Trust us.
        </span>
      </div>

      <div className="grid lg:flex lg:space-x-4 sm:px-[0] md:px-[0] lg:px-[10%] items-center py-8 lg:py-16 items-center lg:place-content-between wrap w-[100%]">
        <a href="https://www.psssf.go.tz/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={pssfImg}
              className={`${partnerImg} sm:w-full sm:h-[200px] md:w-[90px] md:h-[100px] lg:w-[110px] lg:h-[120px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />

          </div>
        </a>
        <a href="https://www.tanzania.go.tz/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={govImg}
              className={`${partnerImg} sm:w-full sm:h-[200px] md:w-[100px] md:h-[100px] lg:w-[100px] lg:h-[100px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />

          </div>
        </a>
        <a href="https://www.tanesco.co.tz/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={tanescoImg}
              className={`${partnerImg} sm:w-full sm:h-[200px] md:w-[100px] md:h-[100px] lg:w-[120px] lg:h-[120px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />

          </div>
        </a>
        <a href="https://www.millicom.com/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={tigoImg}
              className={`${partnerImg} sm:w-full sm:h-[200px] md:w-[100px] md:h-[100px] lg:w-[100px] lg:h-[100px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />

          </div>
        </a>
        <a href="https://mhbbank.co.tz/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={mwangaImg}
              className={`${partnerImg} sm:w-full sm:h-[80px] md:w-[100px] md:h-[100px] lg:w-[160px] lg:h-[160px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />

          </div>
        </a>
        <a href="https://vodacom.co.tz/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={vodaImg}
              className={`${partnerImg} sm:w-full sm:h-[150px] md:w-[90px] md:h-[90px] lg:w-[90px] lg:h-[90px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />

          </div>
        </a>
        <a href="https://www.nhc.co.tz/" target='_blank' rel="noreferrer">
          <div className={`${partnerImgContainer}`}>
            <img
              src={nhcImg}
              className={`${partnerImg} sm:w-full sm:h-[200px] md:w-[100px] md:h-[100px] lg:w-[100px] lg:h-[100px] sm:my-[20px] md:my-[0px]`}
              alt=""
            />
          </div>
        </a>
      </div>
    </div>
  );
}

export default OurPartners;
