import React, { useEffect } from 'react';
import { ConnectedRouter } from "connected-react-router";
import { PersistGate } from "redux-persist/integration/react";
import { Switch } from "react-router-dom";
import { Provider } from "react-redux";
import persistor from "./store/persistor";
import store, { history } from "./store";
import RouterMiddleware from "./routes/router-middleware";
import routes from "./routes";
import MainLayout from "./components";
import 'draft-js/dist/Draft.css';

function App() {

  useEffect(() => {

  }, [])

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Switch>
            {routes.map(({ path, component, exact }, index) => (
              <RouterMiddleware
                key={index}
                path={path}
                layout={MainLayout}
                component={component}
                exact={exact}
              />
            ))}
          </Switch>
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  );
}

export default App;
