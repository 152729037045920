import {
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAIL,
  CHANGE_LANGUAGE,
  GET_STORIES,
  GET_STORIES_SUCCESS,
  GET_STORIES_FAIL,
  SET_SELECTED_STORY,
  SET_SELECTED_STAFF,
  GET_STAFF,
  GET_STAFF_SUCCESS,
  GET_STAFF_FAIL,
  GET_CARREERS,
  GET_CARREERS_SUCCESS,
  GET_CARREERS_FAIL,
  SET_SELECTED_CARREER,
} from './action-types';

// LANGUAGE
export const changeLanguage = language => ({
  type: CHANGE_LANGUAGE,
  payload: language,
});

export const changeLanguageSuccess = user => ({
  type: CHANGE_LANGUAGE_SUCCESS,
  payload: user,
});

export const changeLanguageFail = error => ({
  type: CHANGE_LANGUAGE_FAIL,
  payload: error,
});

// STORY
export const getStories = (payload) => ({
  type: GET_STORIES,
  payload,
});
export const getStoriesSuccess = (payload) => ({
  type: GET_STORIES_SUCCESS,
  payload,
});
export const getStoriesFail = (payload) => ({
  type: GET_STORIES_FAIL,
  payload,
});

export const setSelectedStory = (payload) => ({
  type: SET_SELECTED_STORY,
  payload,
});

//STAFF
export const getStaff = (payload) => ({
  type: GET_STAFF,
  payload,
});
export const getStaffSuccess = (payload) => ({
  type: GET_STAFF_SUCCESS,
  payload,
});
export const getStaffFail = (payload) => ({
  type: GET_STAFF_FAIL,
  payload,
});

export const setSelectedStaff = (payload) => ({
  type: SET_SELECTED_STAFF,
  payload,
});

// CARREERS
export const getCarreers = (payload) => ({
  type: GET_CARREERS,
  payload,
});
export const getCarreersSuccess = (payload) => ({
  type: GET_CARREERS_SUCCESS,
  payload,
});
export const getCarreersFail = (payload) => ({
  type: GET_CARREERS_FAIL,
  payload,
});

export const setSelectedCarreer = (payload) => ({
  type: SET_SELECTED_CARREER,
  payload,
});