import axios from 'axios';
import { authToken } from './authToken';

export const dev = false;

const environments = {
  development: 'http://127.0.0.1:8090/dermgroup/api/',
  production: 'https://api.neemagospelchoir.org/dermgroup/api/',
  // production: 'https://api.derm.co.tz/dermgroup/api/'
};

const BASE_API_URL = dev ? environments.development : environments.production

const api = (authenticate) => {
  const config = axios.create({
    baseURL: BASE_API_URL
  });

  if (authenticate) { config.defaults.headers.common.Authorization = `Bearer ${authToken('access')}`; }
  config.defaults.headers.post['Content-Type'] = 'application/json';

  return config;
};

export default api;
