/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import Proptypes from 'prop-types'
import OurCompanies from '../../components/ourCompanies/OurCompanies';

import './Stories.css';
import { commonClassNames, styles } from '../../utils/theme/styles';
import { Link, useHistory } from 'react-router-dom';
import { getStories, setSelectedStory } from '../../store/app/actions';
import { connect } from 'react-redux';

function Stories({
  onGetStories,
  stories,
  selectedStory,
  onSetSelectedStory
}) {

  const _history = useHistory()

  useEffect(() => {
    onGetStories()
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="">
      {/* banner  */}
      <div className="story-bg w-full lg:h-[55vh] md:h-[50vh] sm:h-[40vh] bg-no-repeat bg-cover">
        <div className={commonClassNames('lg:h-[55vh] md:h-[50vh] sm:h-[40vh]').transparentImageForegroundContentContainerClass} style={styles.transparentBackgroundImage}>
          <div className={commonClassNames().transparentImageForegroundContentClass}>
            <h1 className={commonClassNames().transparentImageForegroundTitle}>
              Welcome to
              <br />
              our Blog
            </h1>
          </div>
        </div>
      </div>
      {/* stories */}
      <div className="bg-[#F8F8F8] px-6 sm:py-[30px] lg:py-[50px] lg:px-32 mb-[20px]">
        <div className="py-5 grid gap-4">
          <h1 className={commonClassNames().titleClass}>
            The latest at Derm Group
          </h1>
          <p className={commonClassNames().paragraphTextClass}>
            Latest information and news.
          </p>
        </div>
        <div className="grid lg:grid-cols-3 gap-5">
          {stories.map((item, idx) => (
            <div key={idx}
              style={{ cursor: 'pointer' }}
              className={`grid bg-white w-full h-[600px] border-[2px] border-[#D8D8D8] mb-[20px] justify-between rounded-2xl rounded-2xl`}
              onClick={() => {
                onSetSelectedStory(item)
                _history.push(`/stories/${item.id}/`)
              }}
            >
              <div className="pt-8 px-8 h-[250px]">
                <h1 className="font-bold text-[22px] lg:text-[25px] text-[#88000F] lg:mb-5">
                  {item.story_title.length < 65 ? item.story_title : `${item.story_title.substr(0, 65)}...`}
                </h1>
                <h1 className="text-[15px] lg:text-[16px] text-[#898484] ">
                  {item.company} | {item.story_date}
                </h1>
              </div>
              <div className="rounded-2xl lg:h-[450px]">
                <img
                  src={item.story_image}
                  alt=""
                  className="w-full h-[350px] rounded-bl-2xl rounded-br-2xl cover-image-fit"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

Stories.propTypes = {
  stories: Proptypes.array,
  selectedStory: Proptypes.any,
  loading: Proptypes.bool,
  onSetSelectedStory: Proptypes.func,
  onGetStories: Proptypes.func,
}


const mapStateToProps = ({
  app: { stories, selectedStory, loading }
}) => ({
  stories, selectedStory, loading
})

const mapDispatchToProps = dispatch => ({
  onGetStories: () => dispatch(getStories()),
  onSetSelectedStory: payload => dispatch(setSelectedStory(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Stories);