/* eslint-disable linebreak-style */
import React from 'react';
import { Link } from 'react-router-dom';
import { AiFillYoutube, AiOutlineInstagram, AiOutlineTwitter, } from 'react-icons/ai';
import { GrFacebookOption, GrLinkedinOption } from 'react-icons/gr';
import { RiSendPlaneFill } from 'react-icons/ri';
// import FooterIllustration from '../../assets/images/Footer-illustration.png';
import '../sharedStyle.css';

function Footer() {
  const headingStyle = 'headingText text-[#434343] leading-[24.17px] text-[14.11px] font-semibold md:text-xl lg:text-lg';
  const subscribeStyle = 'text-[#88000F] text-[25.11px] font-semibold md:text-xl lg:text-xl';

  const socialIconStyle = 'border border-[#898B89] hover:border hover:border-transparent hover:bg-[#88000F] text-[#898B89] hover:text-white h:text-white h-10 w-10 rounded-full grid place-items-center text-2xl cursor-pointer';
  const inputStyle = 'bg-white border text-black border-[#979897] h-[55px] w-full lg:w-[350px] rounded-full pl-8';
  const contentStyle = 'text-[#979897] font-medium lg:text-[14px] xl:text-[16px] ';
  const footerStyle = 'bg-white flex sm:flex-col md:flex-row lg:flex-row justify-between sm:px-[20px] md:px-[30px] lg:px-[10%] xl:px-[15%] 2xl:px-[15%] pb-[50px] ';
  const footerLinkStyle = 'hover:text-[#88000F]';
  return (
    <div className="">
      <div className={footerStyle}>
        {/* find us */}
        <div className="text-white z-40">
          <div className="mb-2 lg:mb-10">
            <h1 className={headingStyle}>Find Us</h1>
          </div>
          <div className={`${contentStyle} mb-6`}>
            <p>Skycity Mall, 8th Floor,</p>
            <p>Plot 403/1-2, Block A,</p>
            <p>Mlalakuwa, University Road</p>
          </div>
          <div className={contentStyle}>
            <p>P.O.BOX 4756,</p>
            <p>Dar es Salaam, Tanzania,</p>
            <p>Email: info@derm.co.tz</p>
          </div>
        </div>
        {/* our companies */}
        <div className="">
          <div className="mb-2 lg:mb-8">
            <h1 className={headingStyle}>Our Companies</h1>
          </div>
          <div className={`${contentStyle} grid gap-6`}>
            <a href={'https://www.derm.co.tz/'} target='_blank' rel="noreferrer">Derm Group (T) Ltd</a>
            <a href={'https://derm.co.tz/skyline/'} target='_blank' rel="noreferrer">Skyline Properties Limited</a>
            <a href={'#'} rel="noreferrer">Coast Concrete Poles</a>
            <a href={'https://derm.co.tz/electrics/derm-elevators/'} target='_blank' rel="noreferrer">Derm Elevetors</a>
          </div>
        </div>
        {/* quick links */}
        <div className="text-white">
          <div className="mb-2 lg:mb-8">
            <h1 className={headingStyle}>Quick Links</h1>
          </div>

          <ul className={`${contentStyle} grid gap-6`}>
            {/* <Link className={`${footerLinkStyle}`} to="/careers/">
              <li>Career</li>
            </Link> */}
            <Link className={`${footerLinkStyle}`} to="/stories/">
              <li>Stories</li>
            </Link>
            <Link className={`${footerLinkStyle}`} to="/gallery/">
              <li>Gallery</li>
            </Link>
            <Link className={`${footerLinkStyle}`} to="/hse/">
              <li>HSE</li>
            </Link>
            <Link className={`${footerLinkStyle}`} to="/lnk/">
              <li>Derm Staff Portal</li>
            </Link>
          </ul>
        </div>
        {/* subscribe */}
        <div className="text-white z-40">
          <div className="mb-2 lg:mb-8">
            <h1 className={subscribeStyle}>Subscribe to our newsletter</h1>
          </div>
          <div className="flex flex-row items-center">
            <input
              type="email"
              name=""
              className={inputStyle}
              placeholder="Your email Address"
            />
            <div className="h-[56px] w-[98px] bg-[#BB7488] rounded-full flex items-center justify-center relative left-[-98px] ">
              <RiSendPlaneFill className="text-3xl text-white" />
            </div>
          </div>
        </div>
      </div>
      <div className="px-6 lg:px-32 xl:px-32 2xl:px-64">
        <hr className="border-0.5 border-[#BFBFBF]" />
      </div>
      <div className="bg-white grid lg:flex gap-4 items-center justify-between px-6 lg:px-32 xl:px-32 2xl:px-64 py-6 lg:py-4 lg:pb-[99px]">
        <Link to={'/'}>
          <p className="text-[13px] text-[#001C30] font-normal">
            {new Date().getFullYear()} © Derm Group | All rights reserved.
          </p></Link>
        <div className="">
          <ul className="flex gap-6 ">
            <a target={'_blank'} rel="noreferrer" href="https://www.instagram.com/dermgrouptz/">
              <li className={socialIconStyle}>
                {/* eslint-disable-next-line */}
                <AiOutlineInstagram />
              </li>
            </a>
            <li className={socialIconStyle}>
              {/* eslint-disable-next-line */}
              <AiFillYoutube />
            </li>
            <li className={socialIconStyle}>
              {/* eslint-disable-next-line */}
              <AiOutlineTwitter />
            </li>
            <li className={socialIconStyle}>
              <a target={'_blank'} rel="noreferrer" href="https://facebook.com/dermgrouptz">
                {/* eslint-disable-next-line */}
                <GrFacebookOption />
              </a>
            </li>
            <li className={socialIconStyle}>
              <a target={'_blank'} rel="noreferrer" href="https://tz.linkedin.com/company/derm-group-t-ltd">
                {/* eslint-disable-next-line */}
                <GrLinkedinOption />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
