import api from './config';

export const authenticateUser = (credentials) => {
  return api(false).post('auth/token/', credentials);
};


export const getStoriesApi = async () => {
  return await api().get('stories/')
};

export const getStaffApi = async () => {
  return await api().get('members/')
};

export const getCarreersApi = async () => {
  return await api().get('vacancies/')
};
