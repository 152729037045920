/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown, IoIosArrowDropright } from 'react-icons/io';
import PropTypes from 'prop-types'
import ceoImg from '../../assets/images/ceo.jpeg';
import qouteImg from '../../assets/images/quote.png';
import BuldingImg from '../../assets/images/building-illustration.png';
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import './About.css';
import '../../components/sharedStyle.css';
import { commonClassNames, styles } from '../../utils/theme/styles';
import { connect } from 'react-redux';
import { getStaff, setSelectedStaff } from '../../store/app/actions';
import { useHistory } from 'react-router';
import { BsArrowRight } from 'react-icons/bs';

function Managenent({
  onGetStaff,
  staff,
  loading,
  onSetSelectedStaff
}) {

  const _history = useHistory()

  useEffect(() => {
    // window.scrollTo(0, 0)
    onGetStaff()
  }, [onGetStaff])

  const title = 'headingText text-[31px] font-bold mb-[20px]';
  const container = 'border-2 border-[#DADADA] px-8 py-[40px] rounded-2xl';
  const descSize = 'text-[20px] tagText';
  const tagText = 'tagText text-underline font-bold text-2xl ';
  return (
    <div className="">
      {/* banner  */}
      <div className="management-bg w-full lg:h-[55vh] md:h-[50vh] sm:h-[40vh] bg-no-repeat bg-cover">
        <div className={commonClassNames('lg:h-[55vh] md:h-[50vh] sm:h-[40vh]').transparentImageForegroundContentContainerClass} style={styles.transparentBackgroundImage}>
          <div className={commonClassNames().transparentImageForegroundContentClass}>
            <h1 className={commonClassNames().transparentImageForegroundTitle}>
              Dedication <br />
              To Achieving
              <br /> Excellence
            </h1>
          </div>
        </div>
      </div>
      {/* leadership */}
      <div className="px-6 lg:px-32 2xl:px-[300px] bg-[#F8F8F8] lg:pt-[100px] md:pt-[30px] pb-[30px]">
        <div className="lg:flex w-full">
          <div className=" bg-white w-full lg:w-1/3 2xl:w-1/3 lg:rounded-tl-3xl lg:rounded-bl-3xl">
            <div className="rounded-2xl lg:h-[504px] md:h-[750px] sm:h-[750px]">
              <img src={ceoImg} alt="" className="h-full w-full cover-image-fit rounded-tl-lg" />
            </div>
            <div className="h-[150px] lg:h-[230px] pt-8 px-8">
              <h1 className="headingText font-bold text-[25px] lg:text-[25px] text-[#88000F] ">
                Eng. Ridhuan Mringo
              </h1>
              <h1 className="headingText text-[18px] lg:text-[22.04px] text-[#898484] mt-[10px]">
                Chairman
              </h1>
              <div className='text-[#898484] text-[12px]'>VISIONARY ENGINEER AND ENTREPRENEUR</div>
              <button className='w-[160px] h-[40px] flex flex-row justify-center items-center bg-[#88000F] rounded-md text-white cursor-pointer mt-[20px] hover:opacity-90 active:opacity-80 '
                onClick={() => {
                  onSetSelectedStaff(null)
                  _history.push(`/staff/NaN/`)
                }}
              >
                Chairman&apos;s Bio <BsArrowRight className='ml-[10px] mt-[2px] ' />
              </button>
            </div>
          </div>
          <div className="bg-[#ffffff] w-full lg:w-2/3 text-[#505050] headingText text-[22.43px] grid gap-3 lg:rounded-tr-3xl lg:rounded-br-3xl px-8 lg:px-16 pt-[35px] pb-[80px] ">
            <div className="flex gap-2">
              <img src={qouteImg} className="h-[108.59px] w-[54.64px] cover-image-fit" alt="" />
              <img src={qouteImg} className="h-[108.59px] w-[54.64px] cover-image-fit" alt="" />
            </div>
            <span className="text-xl mt-[20px]">
              {
                `For 25 years now, Derm Group has been involved in many major construction and engineering projects – gaining invaluable experience and expertise, and acquiring a reputation as one of the leading electrical contractors in Tanzania. `
              }
            </span>
            <span className="text-xl">
              {
                `We have successfully completed many high-profile contracts, in both the private and the public sectors, where our on-time, in-budget performance and our stringent technical standards have demonstrated our total commitment to every aspect of our operations. I am proud to be at the head of a skilled and dedicated team of engineers, managers and support staff. `
              }
            </span>
            <span className="text-xl">
              {
                `My aim is to ensure the ongoing success of the company by developing their talents and abilities, and by expanding our activities into ever-more technically challenging fields. `
              }
            </span>
            <span className="text-xl">
              {
                `I relish the prospect of taking the company forward in this increasingly demanding and competitive world by building on our special core values and skills.`
              }
            </span>
            <span className="text-xl">
              {
                `Above all, I am determined that Derm Group will maintain its reputation for quality and reliability.`
              }
            </span>
          </div>
        </div>
        {/* img */}
        <img
          src={BuldingImg}
          className="absolute hidden lg:block lg:top-[2400px] xl:top-[2250px] lg:h-[1043px] xl:h-[1083px] 2xl:h-[880px]  lg:left-[640px] xl:left-[610px] 2xl:top-[3000px] 2xl:left-[1200px] lg:w-[650px] 2xl:w-[800px] 2xl:hidden"
          alt=""
        />
      </div>
      {/* team */}
      <div className="bg-[#F8F8F8] px-4 lg:px-32 2xl:px-[300px] 3xl:px-[400px] pb-16 mb-[50px]">
        <div className="py-8 lg:py-16 px-2 lg:px-12">
          <span className={`${tagText} text-[#88000F]`}>MANAGEMENT</span>
        </div>
        <div className=" bg-[#F8F8F8] flex flex-wrap md:gap-2 h-auto ">
          {staff?.map((data, idx) => (
            <div key={idx} className=" bg-[#88000F] rounded-2xl w-full lg:w-[32%] lg:h-[600px] xl:h-[680px] border-4 border-[#D8D8D8] mb-8 lg:mb-2"
              style={{
                cursor: 'pointer'
              }}
              onClick={() => {
                onSetSelectedStaff(data)
                _history.push(`/staff/${data.id}/`)
              }}
            >
              <div className="rounded-2xl lg:h-[420px] xl:h-[500px]">
                <img
                  src={data.profile}
                  alt=""
                  className="lg:h-[420px] xl:h-[500px] w-full rounded-tl-xl rounded-tr-xl cover-image-fit"
                />
              </div>
              <div className="h-[230px] pt-5">
                <h1 className="font-semibold text-[25px] text-white mb-0 px-[20px]">
                  {data.name}
                </h1>
                <div className="flex items-center justify-between w-full px-[20px] pt-[20px]">
                  <h1 className="font-bold text-[#DBB2B7]">
                    {data.position}<br />{data.company}
                  </h1>
                  <div className="border-[1px] border-white rounded-full h-10 w-10 grid place-content-center mr-[30px] mt-[2px]">
                    <BsArrowRight className="text-white text-xl" />
                  </div>
                </div>
                {/* <h1 className="text-[#DBB2B7] px-[20px] relative top-[-5px]"></h1> */}
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

Managenent.propTypes = {
  loading: PropTypes.bool,
  onGetStaff: PropTypes.func,
  onSetSelectedStaff: PropTypes.func,
  staff: PropTypes.array
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading, staff }
}) => ({
  stories, selectedStory, loading, staff
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onSetSelectedStaff: payload => dispatch(setSelectedStaff(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Managenent);
