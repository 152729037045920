/* eslint-disable linebreak-style */
import React from 'react';
import ReactSwipe from 'react-swipe';

function Test() {
  let slide;

  return (
    <div>
      <ReactSwipe
        className="h-[500px]"
        swipeOptions={{ continuous: false }}
        ref={(y) => {
          slide = y;
        }}
      >
        <div className="bg-orange-300 h-[400px]">PANE 1</div>
        <div className="bg-green-300 h-[400px]">PANE 2</div>
        <div className="bg-slate-400 h-[400px]">PANE 3</div>
      </ReactSwipe>
      <button type="button" onClick={() => slide.next()}>
        Next
      </button>
      <button type="button" onClick={() => slide.prev()}>
        Previous
      </button>
    </div>
  );
}

export default Test;
