import {
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_FAIL,
  CHANGE_LANGUAGE,
  GET_STAFF,
  GET_STORIES,
  GET_CARREERS,
  GET_STORIES_SUCCESS,
  GET_STAFF_SUCCESS,
  GET_CARREERS_SUCCESS,
  SET_SELECTED_STAFF,
  SET_SELECTED_STORY,
  GET_STORIES_FAIL,
  GET_STAFF_FAIL,
  GET_CARREERS_FAIL,
  SET_SELECTED_CARREER,
} from './action-types';

const INIT_STATE = {
  name: 'dermgroup',
  version: '0.0.1',
  language: 'en',
  error: null,
  stories: [],
  selectedStory: null,
  staff: [],
  selectedStaff: null,
  carreers: [],
  selectedCarreer: null,
  loading: false,
};

const app = (state = INIT_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_LANGUAGE:
    case GET_STAFF:
    case GET_STORIES:
    case GET_CARREERS:
      return { ...state, loading: true };

    case CHANGE_LANGUAGE_SUCCESS:
      return {
        ...state,
        language: payload,
        error: null,
        loading: false,
      };

    // STORIES
    case GET_STORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        stories: payload
      }
    case SET_SELECTED_STORY:
      return {
        ...state,
        loading: false,
        selectedStory: payload
      }

    // STAFF
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        loading: false,
        staff: payload
      }
    case SET_SELECTED_STAFF:
      return {
        ...state,
        loading: false,
        selectedStaff: payload
      }

    //CARREERS
    case GET_CARREERS_SUCCESS:
      return {
        ...state,
        loading: false,
        carreers: payload
      }
    case SET_SELECTED_CARREER:
      return {
        ...state,
        loading: false,
        selectedCarreer: payload
      }

    case CHANGE_LANGUAGE_FAIL:
    case GET_STORIES_FAIL:
    case GET_STAFF_FAIL:
    case GET_CARREERS_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
        success: null,
      };

    default:
      return state;
  }
};

export default app;
