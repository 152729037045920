/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import capabilityImg from '../../assets/images/artboard-about-1.jpg';
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import './About.css';
import '../../components/sharedStyle.css';
import { commonClassNames, styles } from '../../utils/theme/styles';
import { connect } from 'react-redux';

import SkylineCompany from '../../assets/images/companies/skyline-3.jpg';
import DermElevatorCompany from '../../assets/images/companies/derm-elevators-3.jpg';
import CoastConcreteCompany from '../../assets/images/companies/coast-concrete-3.jpg';
import DermElectricCompany from '../../assets/images/companies/derm-electric-3.jpg';


const Company = ({
  image,
  title,
  summary,
  reversed
}) => {
  return (
    <div className={`${reversed ? 'flex-row-reverse' : 'flex-row'} flex items-center justify-between lg:px-[10%] xl:px-[14%] sm:px-[1%] mt-[60px] flex-wrap`}>
      <div className={`lg:h-[450px] lg:w-[49%] sm:h-[auto] sm:w-[98%] lg:px-[50px] sm:px-[15px] lg:py-[70px] sm:py-[15px] border-[#DADADA] border-[2px] lg:rounded-[25px] ${reversed ? 'bg-white' : 'bg-[#88000F]'} ${reversed ? 'text-[#88000F]' : 'text-white'}`}>
        <h1 className='font-bold text-[30px] '>{title}</h1>
        <p className='2xl:text-[18px] lg:text-[16px] mt-[20px] '>
          {summary}
        </p>
      </div>
      <div className='lg:h-[450px] lg:w-[49%] sm:h-[auto] sm:w-[98%] lg:rounded-[25px] '>
        <img src={image} className='h-[100%] w-[100%] lg:rounded-[25px]  ' />
      </div>
    </div>
  )
}

Company.propTypes = {
  image: PropTypes.any,
  title: PropTypes.any,
  summary: PropTypes.any,
  reversed: PropTypes.bool
}


function About({
}) {


  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [])

  const title = 'headingText text-[31px] font-bold mb-[20px]';
  const container = 'border-2 border-[#DADADA] px-8 py-[40px] rounded-2xl';
  const descSize = 'text-[20px] tagText';
  const tagText = 'tagText text-underline font-bold text-2xl ';
  return (
    <div className="">
      {/* banner  */}
      <div className="about-bg w-full lg:h-[55vh] md:h-[50vh] sm:h-[40vh] bg-no-repeat bg-cover">
        <div className={commonClassNames('lg:h-[55vh] md:h-[50vh] sm:h-[40vh]').transparentImageForegroundContentContainerClass} style={styles.transparentBackgroundImage}>
          <div className={commonClassNames().transparentImageForegroundContentClass}>
            <h1 className={commonClassNames().transparentImageForegroundTitle}>
              Dedication <br />
              To Achieving
              <br /> Excellence
            </h1>
          </div>
        </div>
      </div>
      {/* about */}
      <div id='about-us'></div>
      <div className="grid lg:mt-[100px] md:mt-[60px] sm:mt-[40px]">
        <h1 className={commonClassNames().titleClass}>
          About Us
        </h1>
        <p className={commonClassNames().paragraphTextClass}>
          {
            `
            Derm Group is a Tanzanian-owned conglomerate with a diverse portfolio of businesses aimed at delivering high-quality services and products across multiple sectors. 
            Established in October 2017, Derm Group comprises four key subsidiaries: Derm Group (T) Ltd, 
            Skyline Properties Limited, Derm Elevators Limited, and Coast Concrete Poles Ltd. Over the past 25 years,
            Derm Group has been deeply involved in numerous major construction and engineering projects,
            gaining invaluable experience and solidifying its reputation as one of the leading contractors in Tanzania.`
          }
        </p>
      </div>
      <div className="grid gap-16 lg:gap-32 py-6 lg:py-24 px-6 lg:px-16 xl:px-16 2xl:px-[200px] 3xl:px-[300px] w-full bg-[#F8F8F8]">
        <div className="grid lg:flex gap-6">
          <div className={`${container} bg-[#88000F] sm:w-full lg:w-[34%]`}>
            <h1 className={`${title} text-white`}>Company</h1>
            <p className={`${descSize} text-[#DBB2B7]`}>
              Derm Group has 25 years of civil engineering experience in East
              Africa, Elevators, Real Estate and Concrete Poles. Establishing
              itself as the leader in the industry, having handed over many
              iconic projects and built more Construction and Electric works in
              Tanzania than any other company.
            </p>
          </div>
          <div className={`${container} w-full lg:w-[33%]`}>
            <h1 className={`${title} text-[#88000F]`}>Mission</h1>
            <p className={`${descSize} text-[#AA4A55]`}>
              To be a catalyst for people and communities to realize their
              potential, exercise their social responsibility and drive change
              in society.
            </p>
          </div>
          <div className={`${container} w-full lg:w-[33%]`}>
            <h1 className={`${title} text-[#88000F]`}>Vision</h1>
            <p className={`${descSize} text-[#AA4A55]`}>
              Empower marginalized and disadvantaged communities by creating
              innovative, long-term sustainable solutions that improves their
              economic, environmental and social situation, thereby transforming
              their quality of life.
            </p>
          </div>
        </div>
      </div>
      {/* capabilities */}
      <div className="lg:h-[500px] xl:h-[600px] md:h-[400px] md:h-[300px] bg-[#F8F8F8] lg:px-[5%]">
        <div
          style={{
            backgroundImage: `url(${capabilityImg})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
          }}
          className="h-full py-0 md:py-0 2xl:py-0 md:px-[0] sm:px-[0] lg:px-[0] lg:rounded-xl"
        >
          <div className="h-full lg:py-[100px] md:py-[50px] 2xl:py-[100px] md:px-[50px] sm:px-[40px] lg:px-[80px] lg:rounded-xl" style={{ backgroundColor: 'rgba(0,0,0,0.45)' }}>
            <h1 className="tagText capability-underline text-[20px] 2xl:text-[25px] opacity-75 font-semibold uppercase text-white">
              OUR CAPABILITIES
            </h1>
            <h1 className="headingText text-[35px] md:text-[45px] 2xl:text-[60px] font-extrabold text-white">
              Leading construction
              <br /> Group of companies
              <br /> in Tanzania
            </h1>
            <h1 className="tagText text-[25px] 2xl:text-[30px] text-white opacity-75">
              Let&apos;s discuss your project.
            </h1>
          </div>
        </div>
      </div>

      {/* SUBSIDIARIES SUMMARIZED */}

      <Company
        title={'Derm Group (T) Ltd'}
        summary={
          `
          Derm Group (T) Ltd is the cornerstone of our conglomerate, holding a Class 1 registration with the Contractors Registration Board (CRB) of Tanzania. Recognized as the country’s largest and fastest-growing Tanzanian-owned company, Derm Group (T) Ltd is known for its professionalism, reliability, and technical expertise. We provide economically viable and technically superior services across the Electrical, HVAC, ICT, and Telecommunication industries.
          `
        }
        image={DermElectricCompany}
      />
      <Company
        title={'Skyline Properties Limited'}
        summary={
          `
Founded in 2009, Skyline Properties Limited is a pioneering force in Tanzania’s real estate and construction sector. Our mission is to elevate the standard of living and working environments by offering luxury, affordable housing, and commercial spaces. With a reputation built on quality and excellence, Skyline Properties has experienced steady growth, driven by our passionate team and visionary founders. Our expertise lies in real estate development and construction, where we aim to bridge the housing gap caused by Tanzania's increasing population.          `
        }
        image={SkylineCompany}
        reversed={true}
      />
      <Company
        title={'Derm Elevators Limited'}
        summary={
         `
         Derm Group (T) Ltd is the cornerstone of our conglomerate, holding a Class 1 registration with the Contractors Registration Board (CRB) of Tanzania. Recognized as the country’s largest and fastest-growing Tanzanian-owned company, Derm Group (T) Ltd is known for its professionalism, reliability, and technical expertise. We provide economically viable and technically superior services across the Electrical, HVAC, ICT, and Telecommunication industries.
         `
        }
        image={DermElevatorCompany}
      />
      <Company
        reversed={true}
        title={'Coast Concrete Poles Ltd'}
        summary={
        `
        Founded in 2009, Skyline Properties Limited is a pioneering force in Tanzania’s real estate and construction sector. Our mission is to elevate the standard of living and working environments by offering luxury, affordable housing, and commercial spaces. With a reputation built on quality and excellence, Skyline Properties has experienced steady growth, driven by our passionate team and visionary founders. Our expertise lies in real estate development and construction, where we aim to bridge the housing gap caused by Tanzania's increasing population.
        `
        }
        image={CoastConcreteCompany}
      />

      <div className='lg:h-[100px] md:h-[50px]' />
      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

About.propTypes = {
  loading: PropTypes.bool,
  onGetStaff: PropTypes.func,
  onSetSelectedStaff: PropTypes.func,
  staff: PropTypes.array
}

const mapStateToProps = ({
}) => ({
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(About);
