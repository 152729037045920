import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import app from './app/reducer';

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    app
  });
export default rootReducer;
