export const styles = {
	card: {
		borderRadius: 8,
		border: 'none'
	},
	transparentBackgroundImage: {
		backgroundColor: 'rgba(0,0,0,0.5)'
	}
}


export const commonClassNames = (height) => ({
	transparentImageForegroundContentContainerClass: `relative inset-0 z-10 grid content-between ${height ? height : 'lg:h-[80vh] md:h-[80vh] sm:h-[60vh]'}`,
	transparentImageForegroundContentClass: `grid gap-10 justify-items-start sm:ml-[15%] md:ml-[20%] lg:ml-[45%] xl:ml-[60%] 2xl:ml-[60%] lg:mt-[120px] md:mt-[100px] sm:mt-[100px]`,
	transparentImageForegroundTitle: `sm:text-[30px] md:text-[48.14px] lg:text-[65px] font-bold text-white top-transparent-image-foreground-title`,
	tagText: 'tagText text-[#434343] text-[26px] font-medium text-center mb-12',
	titleClass: 'headingText text-[#88000F] lg:text-[40px] md:text-[25px] sm:text-[25px] text-center font-bold',
	paragraphTextClass: 'tagText xl:text-[22px] lg:text-[16px] text-center text-[#898484] mb-[60px] mt-[20px] lg:mx-[15vw] md:mx-[30px] sm:mx-[20px]'
})