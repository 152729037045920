import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Modal, Row } from 'antd';
import PropTypes from 'prop-types'
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import { HiArrowRight } from 'react-icons/hi2';

const _style = {
  label: {
    fontWeight: 'bold'
  },
  input: {
    width: '100%',
  }
}

const CareerDetail = ({
  isOpen,
  onCancel,
  career
}) => {


  const [state, setState] = useState({
    isRaw: true,
    es: null
  })

  const convertContentToEditorState = (content) => {
    if (content) {
      try {
        const es = EditorState.createWithContent(
          convertFromRaw(JSON.parse(content))
        )
        setState({ ...state, isRaw: false, es })
      } catch {
        setState({ ...state, isRaw: true })
      }
    } else {
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    convertContentToEditorState(career.job_description)
  }, [career])

  return (
    <Modal
      title={
        <Row style={{ color: "#CAB52F", width: '100%' }}>
          <span style={{ fontSize: 30, textTransform: 'capitalize', color: '#000' }}>{career.job_title}</span>
        </Row>
      }
      open={isOpen}
      onCancel={onCancel}
      width={'50vw'}
      okButtonProps={{
        type: 'primary'
      }}
      style={{
        padding: '0 30px'
      }}
      footer={[
      ]}
    >
      <div className='text-[20px]'>
        {
          !state.isRaw && <Editor readOnly={true}
            editorState={state.es} />
        }
        {
          state.isRaw && <p>{career.job_description}</p>
        }
      </div>
      <a
        href='mailto:recruitment@derm.co.tz'
        type='button'
        className="bg-[#88000F] my-[50px] rounded-sm hover:bg-grey text-[#FFFFFF] uppercase font-semibold w-[200px] h-[50px] lg:h-[56.95px] lg:w-[236.91px] inline-flex items-center gap-2 justify-center z-40 cursor-pointer"
      >
        <span className="text-md tagText">Apply Now</span>
        <HiArrowRight className="font-bold text-xl" />
      </a>
    </Modal>
  );
};

CareerDetail.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  career: PropTypes.any
}

export { CareerDetail };