import React from "react"
import PropTypes from "prop-types"
import { Route } from "react-router-dom"

const RouterMiddleware = ({ component: Component, layout: Layout, ...rest }) => (
    <Route {...rest} render={props => (
        <Layout>
            <Component {...props} />
        </Layout>
    )} />
)

RouterMiddleware.propTypes = {
    component: PropTypes.any,
    location: PropTypes.object,
    layout: PropTypes.any,
}

export default RouterMiddleware
