export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_LANGUAGE_SUCCESS = 'CHANGE_LANGUAGE_SUCCESS';
export const CHANGE_LANGUAGE_FAIL = 'CHANGE_LANGUAGE_FAIL';

export const GET_STORIES = 'GET_STORIES';
export const GET_STORIES_SUCCESS = 'GET_STORIES_SUCCESS';
export const GET_STORIES_FAIL = 'GET_STORIES_FAIL';

export const SET_SELECTED_STORY = 'SET_SELECTED_STORY';

export const GET_STAFF = 'GET_STAFF';
export const GET_STAFF_SUCCESS = 'GET_STAFF_SUCCESS';
export const GET_STAFF_FAIL = 'GET_STAFF_FAIL';

export const SET_SELECTED_STAFF = 'SET_SELECTED_STAFF'

export const GET_CARREERS = 'GET_CARREERS';
export const GET_CARREERS_SUCCESS = 'GET_CARREERS_SUCCESS';
export const GET_CARREERS_FAIL = 'GET_CARREERS_FAIL';

export const SET_SELECTED_CARREER = 'SET_SELECTED_CARREER'
