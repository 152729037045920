/* eslint-disable linebreak-style */
import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { CgMenuRight } from 'react-icons/cg';
import { BsX } from 'react-icons/bs';
import { HiOutlinePhone } from 'react-icons/hi';
import Logo from '../../assets/images/logo.png';
import '../sharedStyle.css';
import { HashLink } from "react-router-hash-link";

function Header() {
  const [phoneNav, setPhoneNav] = useState(false);
  const { hash, pathname } = useLocation();

  const toggle = () => {
    setPhoneNav(!phoneNav);
  };
  const linkListStyle =
    'h-[50px] pt-[20px] px-[5px] justify-center items-center tagText block text-[#393939] uppercase hover:text-[#88000F] text-[12.87px] font-bold hover:border-b-[2px] hover:border-[#88000F] cursor-pointer';
  const mobileListStyle =
    'tagText block py-2 pr-4 pl-3 text-[#393939] font-semibold';

  const separator = 'h-[1px] bg-[#E8E8E8] rounded-lg '

  const NavLinkStyles = ({ isActive }) => ({
    fontWeight: isActive ? '900' : '700',
    color: isActive ? '#88000F' : '#393939',
    marginBottom: 0,
    borderBottom: isActive ? '3px solid #88000F' : '',
  });

  const dropdownLinkClassName = ({ isActive }) => (
    isActive ? 'border-b-[1px] border-b-[#E8E8E8] border-l-[2px] border-l-[#88000F] border-r-[2px] border-r-[#88000F] font-bold pl-[10px] pr-[15px] py-[15px] text-[16px] text-[#88000F]' :
      'border-b-[1px] border-b-[#E8E8E8] font-semibold text-[16px] pl-[10px] pr-[15px] py-[15px] text-black'
  )

  return (
    <div className="w-full flex justify-between items-center lg:static" style={{ borderBottom: '1px solid #FFFFFF' }}>
      <nav className="lg:py-0 lg:px-0 bg-[#FFFFFF] w-full lg:h-[80px] h-[60px] lg:flex lg:place-content-center navbar-fixed">
        <div className="flex justify-between items-center w-full lg:w-[80%] xl:w-[80%] 2xl:w-[85%] px-6 lg:py-0 lg:px-6">
          <Link to="/">
            <img
              src={Logo}
              alt=""
              className={`mr-3 h-[28px] w-[154.45px] lg:h-[28px] lg:w-[154.45px] sm:h-9`}
            />
          </Link>

          <button
            data-collapse-toggle="navbar-default"
            type="button"
            className="inline-flex items-center p-2 ml-3 text-sm text-black rounded-lg md:hidden "
            aria-controls="navbar-default"
            aria-expanded="false"
            onClick={toggle}
          >
            {phoneNav ? (
              <BsX className="text-3xl text-[#232325]" />
            ) : (
              <CgMenuRight className="text-3xl text-[#232325]" />
            )}
          </button>
          <div
            className="hidden lg:w-[80%] md:block md:w-auto"
            id="navbar-default"
          >
            <ul className="flex flex-col mt-4 md:flex-row md:content-center space-x-8 md:mt-0  md:text-sm font-bold md:font-medium lg:px-0">
              <NavLink
                tag={Link}
                active
                style={NavLinkStyles({ isActive: hash === '' && pathname === '/' })}
                className={`${linkListStyle}`}
                to="/"
              >
                <li>HOME</li>
              </NavLink>
              <div className='nav-dropdown'>
                <NavLink
                  tag={Link}
                  style={NavLinkStyles({ isActive: pathname === '/about/' || pathname == '/management/' || pathname === '/hse/' || pathname === '/lnk/' })}
                  className={`${linkListStyle} nav-dropdown`}
                  to="/about/"
                >
                  ABOUT
                </NavLink>
                <ul className='header-dropdown-container'>
                  <div className={separator} />
                  <NavLink to='/about/' className={dropdownLinkClassName({ isActive: pathname === '/about/' })}>
                    <li className={``}>About DERM Group</li>
                  </NavLink>
                  <NavLink to='/management/' className={dropdownLinkClassName({ isActive: pathname === '/management/' })}>
                    <li className={``}>Management</li>
                  </NavLink>
                  <NavLink to='/hse/' className={dropdownLinkClassName({ isActive: pathname === '/hse/' })}>
                    <li className={``}>HSE</li>
                  </NavLink>
                  <NavLink to='/lnk/' className={dropdownLinkClassName({ isActive: pathname === '/lnk/' })}>
                    Derm Staff Portal
                  </NavLink>
                </ul>

              </div>
              <div className='nav-dropdown'>
                <HashLink
                // tag={Link}
                  style={NavLinkStyles({ isActive: hash === '#companies' })}
                  className={`${linkListStyle} nav-dropdown`}
                  to="/#companies"
                >
                  Subsidiaries
                </HashLink>
                <ul className='header-dropdown-container'>
                  <div className={separator} />
                  <a href='https://www.derm.co.tz/' target='_blank' rel='noreferrer' style={{ zIndex: 20 }} className={dropdownLinkClassName(false)}>
                    Derm Group (T) LTD
                  </a>
                  <a href='https://derm.co.tz/elevators/' target='_blank' rel='noreferrer' className={dropdownLinkClassName(false)}>
                    Derm Elevators
                  </a>
                  <a href='#' target='_blank' rel='noreferrer' className={dropdownLinkClassName(false)}>
                    Coast Concrete poles Ltd
                  </a>
                  <a href='https://skycitymall.co.tz/' target='_blank' rel='noreferrer' className={dropdownLinkClassName(false)}>
                    Skyline Properties Limited
                  </a>
                </ul>
              </div>
              <HashLink to="/#partners" style={NavLinkStyles({ isActive: hash === '#partners' })}>
                <li className={`${linkListStyle}`}>PARTNERS</li>
              </HashLink>
              <Link
                style={NavLinkStyles({ isActive: pathname === '/careers/' })}
                className={`${linkListStyle}`}
                to="/careers/">
                <li>CAREERS</li>
              </Link>
              <NavLink
                style={NavLinkStyles({ isActive: pathname === '/stories/' })}
                className={`${linkListStyle}`}
                to="/stories/"
              >
                <li>NEWS</li>
              </NavLink>
              <NavLink
                style={NavLinkStyles({ isActive: pathname === '/contact/' })}
                className={`${linkListStyle}`}
                to="/contact/">
                <li>CONTACTS</li>
              </NavLink>
            </ul>
          </div>
        </div>
        <div className="bg-[#88000F] text-white lg:flex lg:gap-2 justify-center items-center w-full md:w-[20%] lg:w-[20%] xl:w-[20%] 2xl:w-[20%] px-0 2xl:px-0 cursor-pointer hidden">
          <HiOutlinePhone className="text-[#F2C3C8] text-2xl" />
          <p className="tagText text-[16.6px] ">Call Us: +255 22 277 4507</p>
        </div>
        {/* mobile view */}
        {phoneNav && (
          <div className="w-full mt-2 bg-[#FFFFFF] h-[320px] fixed shadow z-40 px-4">
            <ul className="flex flex-col mt-4 text-md">
              <NavLink to="/">
                <li
                  aria-hidden="true"
                  onClick={toggle}
                  className={`${mobileListStyle}`}>
                  HOME
                </li>
              </NavLink>
              <NavLink to="/about/">
                <li
                  aria-hidden="true"
                  onClick={toggle}
                  className={`${mobileListStyle}`}>
                  ABOUT
                </li>
              </NavLink>
              <NavLink to="/careers/">
                <li
                  aria-hidden="true"
                  onClick={toggle}
                  className={`${mobileListStyle}`}>
                  CAREERS
                </li>
              </NavLink>
              <Link to="/stories/">
                <li
                  aria-hidden="true"
                  onClick={toggle}
                  className={`${mobileListStyle}`}
                >
                  NEWS
                </li>
              </Link>
              <NavLink to="/contact/">
                <li
                  aria-hidden="true"
                  onClick={toggle}
                  className={`${mobileListStyle}`}
                >
                  CONTACTS
                </li>
              </NavLink>
              <NavLink to="/hse/">
                <li
                  aria-hidden="true"
                  onClick={toggle}
                  className={`${mobileListStyle}`}
                >
                  HSE
                </li>
              </NavLink>
            </ul>
            <div className="bg-[#fff] text-[#000] flex gap-2 items-center w-full lg:w-[20%] xl:w-[20%] 2xl:w-[20%] px-2 py-2 lg:hidden">
              <HiOutlinePhone className="text-[#88000F] text-3xl" />
              <p className="tagText text-[16.6px] ">
                Call Us: +255 22 277 4507
              </p>
            </div>
          </div>
        )}
      </nav>
    </div>
  );
}

export default Header;
