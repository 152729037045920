/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { RiSearch2Line } from 'react-icons/ri';
import { BsArrowRight } from 'react-icons/bs';
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import '../../components/sharedStyle.css';
import './Careers.css';
import { commonClassNames, styles } from '../../utils/theme/styles';
import { connect } from 'react-redux';
import { getCarreers, setSelectedCarreer } from '../../store/app/actions';
import { CareerDetail } from './details';

function Careers({
  carreers,
  onGetCarreers,
  onSetSelectedCarreer,
  selectedCarreer,
  loading
}) {

  const [state, setState] = useState({
    showCarrerDetails: false
  })

  useEffect(() => {
    // window.scrollTo(0, 0)
    onGetCarreers()
  }, [])

  return (
    <div className="">
      {/* banner */}
      <div className="career-bg w-full lg:h-[55vh] md:h-[50vh] sm:h-[40vh] bg-no-repeat bg-cover">
        <div className={commonClassNames('lg:h-[55vh] md:h-[50vh] sm:h-[40vh]').transparentImageForegroundContentContainerClass} style={styles.transparentBackgroundImage}>
          <div className={commonClassNames().transparentImageForegroundContentClass}>
            <h1 className={commonClassNames().transparentImageForegroundTitle}>
              Find the career
              <br />
              of your dreams
            </h1>
          </div>
        </div>
      </div>
      {/* careers */}
      <div className="lg:py-16 bg-[#F8F8F8] lg:px-32 px-6 xl:px-48 mb-[50px]">
        <div className={`py-16`}>
          <h1 className={commonClassNames().titleClass}>
            We&apos;re more than just a<br /> workplace. We&apos;re a family.
          </h1>
          <h1 className={`${commonClassNames().tagText} mt-[20px]`}>
            Join us, Current Openings
          </h1>
        </div>
        <div className="lg:flex justify-between items-center grid gap-1">
          <h1 className="tagText text-[#092C4C] font-bold text-[22px]">
            Available Vacancies
          </h1>
          <div className="flex space-x-4">
            <div className="flex">
              <input
                type="text"
                className="tagText hidden bg-white border-[2px] border-[#EAEEF4] focus:ring-4 focus:outline-none focus:ring-transparent  rounded-[32px] text-sm w-[165px] h-[40px] lg:w-[205px] lg:h-[50px] px-4"
              />
              {/* eslint-disable-next-line */}
              <button
                type="button"
                className="bg-white h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] grid place-content-center rounded-full border-[2px] border-[#EAEEF4]"
              >
                <RiSearch2Line className="text-[#7E92A2] text-xl" />
              </button>
            </div>
          </div>
        </div>
        <div className="py-[10px]">
          <hr className="border-0.5 border-[#BFBFBF]" />
        </div>
        {/* list of jobs */}
        <div className="lg:grid lg:grid-cols-3 lg:gap-4 grid gap-0 py-[30px]">
          {carreers.map((data, idx) =>
            <div key={idx} className="bg-white my-[15px] lg:w-full drop-shadow-sm lg:h-[208px] px-6 py-6 grid rounded-[10px] cursor-pointer"
              onClick={() => {
                onSetSelectedCarreer(data)
                setState({ ...state, showCarrerDetails: true })
              }}
            >
              <h1 className="tagText text-[#171B24] font-bold text-[19.5px]">
                {data.job_title}
              </h1>
              <p className="tagText text-[#171B24] font-normal">
                {data.company}
              </p>
              <div className="flex space-x-12 items-center">
                <p className="tagText uppercase text-[#88000F] font-semibold">
                  {data.job_type}
                </p>
                <BsArrowRight className="text-[#88000F]" />
              </div>
            </div>
          )}
        </div>
        {/* </div> */}
        {/* ))} */}
      </div>
      {/* our companies */}
      <OurCompanies />
      {
        selectedCarreer && <CareerDetail
          isOpen={state.showCarrerDetails && selectedCarreer}
          onCancel={() => setState({ ...state, showCarrerDetails: false })}
          career={selectedCarreer}
        />
      }
    </div>
  );
}

Careers.propTypes = {
  carreers: PropTypes.array,
  selectedCarreer: PropTypes.object,
  loading: PropTypes.bool,
  onGetCarreers: PropTypes.func,
  onSetSelectedCarreer: PropTypes.func
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading, carreers, selectedCarreer }
}) => ({
  stories, selectedStory, loading, carreers, selectedCarreer
})

const mapDispatchToProps = dispatch => ({
  onGetCarreers: () => dispatch(getCarreers()),
  onSetSelectedCarreer: payload => dispatch(setSelectedCarreer(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Careers);
