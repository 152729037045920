/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import '../../components/sharedStyle.css';
import { connect } from 'react-redux';
import { getStaff, setSelectedStaff } from '../../store/app/actions';
import { BsArrowRight } from 'react-icons/bs';

function StaffLink({
  selectedStaff
}) {

  document.title = `Derm Group | HSE`

  const [state, setState] = useState({
    links: [
      { title: 'ERP – Derm MV:', link: 'https://dermmv.epmtz.com/' },
      { title: 'ERP – Derm LV:', link: 'https://derm.epmtz.com/' },
      { title: 'ERP – Derm Store:', link: 'https://dermstore.epmtz.com/' },
      { title: 'ERP – Derm elevator:', link: 'https://dermelevators.epmtz.com/' },
      { title: 'ERP – Derm LV:', link: 'https://dermmv.epmtz.com/' },
      { title: 'Aruti:', link: 'http://196.45.37.234/NewUIArutiSelfService/Index.aspx' },
    ],
    loading: false
  })

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <div className="">
      {/* banner  */}
      <div className="w-full lg:h-[25vh] md:h-[25vh] sm:h-[20vh] bg-no-repeat bg-cover"
        style={{
          backgroundColor: '#88000F'
        }}
      >
        <div className={'grid sm:mx-[50px] lg:mx-[13%] xl:mx-[13%] 2xl:mx-[13%] lg:pt-[10vh] sm:pt-[60px] md:pt-[80px]'}>

          <h1 className={'sm:text-[16px] lg:text-[40px] font-500 text-white top-transparent-image-foreground-title uppercase'}>
            Derm Staff Portal
          </h1>
        </div>
      </div>
      {/* about */}
      <div className="lg:grid grid gap-0 pt-[60px] pb-[10px] lg:px-[6%] md:px-10%] bg-[#FOFOFO]" style={{ flexDirection: 'row', display: 'flex', flexWrap: 'wrap' }}>
        {
          state.links.map((item, index) => {
            return (
              <a href={item.link} target='_blank' rel='noreferrer'
                key={`lnk${index}`}
                className={
                  "bg-[#F8F8F8] shadow-md h-[150px] my-[15px] grid rounded-[10px] cursor-pointer text-decoration-none border-[1px] border-[#E5E5E5] " +
                  "md:w-[80%] lg:w-[24%] sm:mx-[0%] md:mx-[0%] lg:mx-[4%]"
                }
                onClick={() => {
                }}
              >
                <h1 className="tagText text-[#171B24] font-bold text-[22px] pt-[20px] px-[15px]">
                  {item.title}
                </h1>
                <div className="flex flex-row justify-between items-center space-x-12 items-center bg-[#F0F0F0] px-[15px] rounded-br-[5px] rounded-bl-[5px]">
                  <p className="tagText uppercase text-[#88000F] font-semibold">
                    Open Link
                  </p>
                  <BsArrowRight className="text-[22px]" />
                </div>
              </a>
            )
          })
        }
      </div>
      <div className='h-[100px]'></div>
      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

StaffLink.propTypes = {
  loading: PropTypes.bool,
  onGetStaff: PropTypes.func,
  staff: PropTypes.array,
  selectedStaff: PropTypes.object
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading, staff, selectedStaff }
}) => ({
  stories, selectedStory, loading, staff, selectedStaff
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onSetSelectedStaff: payload => dispatch(setSelectedStaff(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(StaffLink);
