/* eslint-disable linebreak-style */
const projects = [
  {
    id: 0,
    number: '400+',
    status: 'Completed Projects',
  },
  {
    id: 1,
    number: '25+',
    status: 'Ongoing Projects',
  },
  {
    id: 2,
    number: '25 Yrs+',
    status: 'Professionalism & Excellence',
  },
  {
    id: 3,
    number: '2000+',
    status: 'People Manpower',
  },
  {
    id: 4,
    number: '800B USD +',
    status: 'Projects Value',
  },
];
export default projects;
