import { put, takeEvery, call } from 'redux-saga/effects';

import { changeLanguageFail, changeLanguageSuccess, getCarreersFail, getCarreersSuccess, getStaffFail, getStaffSuccess, getStoriesFail, getStoriesSuccess } from './actions';
import { setActiveLanguage } from '../../utils/locales/helpers/language';
import { CHANGE_LANGUAGE, GET_CARREERS, GET_STAFF, GET_STORIES } from './action-types';
import { getCarreersApi, getStaffApi, getStoriesApi } from '../../utils/api';

function* doChangeAppLanguage({ payload }) {
  try {
    yield put(changeLanguageSuccess(payload));
    yield call(setActiveLanguage, payload);
  } catch (error) {
    yield put(changeLanguageFail(error));
  }
}

function* doGetStories({ payload }) {
  try {
    const response = yield call(getStoriesApi)
    if (response.status === 200) {
      yield put(getStoriesSuccess(response.data));
    } else {
      yield put(getStoriesFail(response.data.message));
    }
  } catch (error) {
    yield put(getStoriesFail('Failed to get data!'));
  }
}

function* doGetStaff({ payload }) {
  try {
    const response = yield call(getStaffApi)
    if (response.status === 200) {
      yield put(getStaffSuccess(response.data));
    } else {
      yield put(getStaffFail(response.data.message));
    }
  } catch (error) {
    yield put(getStaffFail('Failed to fetch data!'));
  }
}

function* doGetCarreers({ payload }) {
  try {
    const response = yield call(getCarreersApi)
    if (response.status === 200) {
      yield put(getCarreersSuccess(response.data));
    } else {
      yield put(getCarreersFail(response.data.message));
    }
  } catch (error) {
    yield put(getCarreersFail('Failed to get data!'));
  }
}

function* appSaga() {
  yield takeEvery(CHANGE_LANGUAGE, doChangeAppLanguage);
  yield takeEvery(GET_STORIES, doGetStories);
  yield takeEvery(GET_STAFF, doGetStaff);
  yield takeEvery(GET_CARREERS, doGetCarreers);
}

export default appSaga;
