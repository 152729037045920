/* eslint-disable linebreak-style */
import React from 'react';
import dermImg from '../../assets/images/logo.png';
import skylineImg from '../../assets/images/skyline-logo.png';
import dermElevatorsImg from '../../assets/images/derm-elevators.png';
import concreteImg from '../../assets/images/concrete.png';
import './OurCompanies.css';
import '../sharedStyle.css';

function OurCompanies() {
  const tagText = 'aboutText-underline tagText tracking-widest font-semibold text-[18px] lg:text-[22px] mb-[5px]';
  const partnerImgContainer = 'relative w-[323.03px] h-[139.77px] lg:w-[323.03px] lg:h-[139.77px] grid place-content-center';
  return (
    <div id="#companies" className="mb-[50px]">
      <div className="px-6 lg:px-32 2xl:px-64 bg-[#FFFFFF] ">
        <div>
          <span className={`${tagText} text-[#88000F]`}>
            WE ARE BEST IN BUSINESS
          </span>
        </div>
        <div style={{ marginTop: '20px' }}>
          <span className={"headingText text-[#88000F] font-bold text-[30px] lg:text-[40px]"}>
            Our
            <br /> Companies.
          </span>
        </div>
        <div className="flex md:space-x-4 lg:flex lg:space-x-4 items-center py-0">
          <div className={`${partnerImgContainer}`}>
            <a href={'https://www.derm.co.tz/'} target='_blank' rel="noreferrer">
              <img
                src={dermImg}
                className="w-[236px] h-[42px] lg:w-[236px] lg:h-[42px] "
                alt=""
              />
            </a>
          </div>
          <div className={`${partnerImgContainer}`}>
            <a href={'https://derm.co.tz/skyline/'} target='_blank' rel="noreferrer">
              <img
                src={skylineImg}
                className="w-[208px] h-[49px] lg:w-[208px] lg:h-[49px]"
                alt=""
              />
            </a>
          </div>
          <div className={`${partnerImgContainer}`}>
            <a href={'https://www.derm.co.tz/derm-elevators/'} target='_blank' rel="noreferrer">
              <img
                src={dermElevatorsImg}
                className="w-[230px] h-[85px] lg:w-[230px] lg:h-[85px] "
                alt=""
              />
            </a>
          </div>
          <div className={`${partnerImgContainer}`}>
            <a href={'https://www.derm.co.tz/'} target='_blank' rel="noreferrer">
              <img
                src={concreteImg}
                className="w-[323.03px] h-[139.77px] lg:w-[323.03px] lg:h-[139.77px]"
                alt=""
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OurCompanies;
