/* eslint-disable linebreak-style */
import banner1Img from '../assets/images/slider/slide-1.jpg';
import banner2Img from '../assets/images/slider/slide-2.jpg';
import banner3Img from '../assets/images/slider/slide-3.jpg';
import banner4Img from '../assets/images/slider/slide-4.jpg';
import banner5Img from '../assets/images/slider/Ubungo.jpg';
import banner6Img from '../assets/images/slider/Ubungo-electric.jpg';
import banner7Img from '../assets/images/slider/coast-concrete.jpg';

const homeBanner = [
  {
    id: 5,
    imgURL: banner6Img,
  },
  {
    id: 6,
    imgURL: banner7Img,
  },
  {
    id: 0,
    imgURL: banner1Img,
  },
  {
    id: 1,
    imgURL: banner2Img,
  },
  {
    id: 2,
    imgURL: banner3Img,
  },
  {
    id: 3,
    imgURL: banner4Img,
  },
  {
    id: 4,
    imgURL: banner5Img,
  },
];
export default homeBanner;
