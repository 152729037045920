/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import PropTypes from 'prop-types'
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import '../../components/sharedStyle.css';
import { connect } from 'react-redux';
import { getStaff, setSelectedStaff } from '../../store/app/actions';
import { commonClassNames } from '../../utils/theme/styles';
import { hseData } from './text';


function HSE({
  selectedStaff
}) {

  document.title = `Derm Group | HSE`

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="">
      {/* banner  */}
      <div className="w-full lg:h-[25vh] md:h-[25vh] sm:h-[20vh] bg-no-repeat bg-cover"
        style={{
          backgroundColor: '#88000F'
        }}
      >
        <div className={'grid sm:mx-[50px] lg:mx-[13%] xl:mx-[13%] 2xl:mx-[13%] lg:pt-[10vh] sm:pt-[60px] md:pt-[80px]'}>

          <h1 className={'sm:text-[16px] lg:text-[40px] font-500 text-white top-transparent-image-foreground-title'}>
            HEALTH AND SAFETY
          </h1>
        </div>
      </div>
      {/* about */}
      <div className="sm:py-[30px] md:py-[50px] lg:py-[80px] lg:px-[15%] sm:px-[5%] bg-[#F8F8F8] mb-[50px]">
        <p className={`lg:text-[22px] md:text-[18px] sm:text-[18px] font-bold`}>
          Derm Group (T) LTD recognizes and is steadfastly dedicated to its responsibility for the health, safety, and welfare of its employees, suppliers, and the general public.
          To uphold this commitment, Derm Group (T) LTD will demonstrate, through policy development, that health and safety receive paramount attention on an ongoing basis.
          The company pledges to ensure, to the extent practicable, that:
        </p>
        <ul className='list-decimal list-inside pl-[1%] pr-[4%] text-[rgba(0,0,0,0.7)] text-[19px] mt-[20px] mb-[50px]'>
          {
            hseData.map((item, index) => {
              return (
                <li key={`hse--${index}`} className='py-[10px] font-400'>{item}</li>
              )
            })
          }
        </ul>
        <p className={`text-[20px] font-normal`}>
          This policy is a foundational outline of Derm Group (T) LTD&apos;s commitment to occupational health and safety within the workplace.
          It is imperative that everyone involved shares this commitment for the achievement and maintenance of the health and safety of all.
        </p>
        <p className={`text-[20px] font-normal py-[20px]`}>Additionally, it is noteworthy that Derm Group (T) LTD holds ISO certifications for
          ISO 9001:2015, ISO 14001:2015, and ISO 45001:2018. The certifications cover the scope of Engineering, Supply, Installation,
          Testing, and Commissioning of electrical, HVAC, Telecommunication, ICT, Security Systems, and mechanical works and support.
          These certifications further emphasize Derm Group (T) LTD&apos;s dedication to quality, environmental sustainability, and occupational health and safety in its operations.
        </p>
      </div>

      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

HSE.propTypes = {
  loading: PropTypes.bool,
  onGetStaff: PropTypes.func,
  staff: PropTypes.array,
  selectedStaff: PropTypes.object
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading, staff, selectedStaff }
}) => ({
  stories, selectedStory, loading, staff, selectedStaff
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onSetSelectedStaff: payload => dispatch(setSelectedStaff(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(HSE);
