import React from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const MainLayout = (props) => {
	const { children } = props;
	return (
		<React.Fragment>
			<Header />
			<div className="page-content">
				{children}
			</div>
			<Footer />
		</React.Fragment>
	)
}

MainLayout.propTypes = {
	children: PropTypes.any
}
const mapStateToProps = ({ app: { language, version, build, name } }) => ({ language, version, build, name })

export default connect(mapStateToProps, {})(withRouter(MainLayout))
