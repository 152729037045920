/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import '../../components/sharedStyle.css';
import { connect } from 'react-redux';
import { getStaff, setSelectedStaff } from '../../store/app/actions';
import { Image } from 'antd';
import api from '../../utils/api/config';


function Gallery({
}) {

  const [gallery, setgallery] = useState({
    images: []
  })

  const getGallery = async () => {
    const response = await api().get('gallery/');
    if (response.status === 200) setgallery({ ...gallery, images: response.data })
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    getGallery()
  }, [])

  return (
    <div className="">
      {/* banner  */}
      <div className="w-full lg:h-[25vh] md:h-[20vh] sm:h-[20vh] bg-no-repeat bg-cover"
        style={{
          backgroundColor: '#88000F'
        }}
      >
        <div className={'grid sm:mx-[50px] lg:mx-[13%] xl:mx-[13%] 2xl:mx-[13%] lg:pt-[10vh] sm:pt-[80px] md:pt-[80px]'}>
          <h1 className={'text-[40px] font-500 text-white top-transparent-image-foreground-title top[-10px]'}>
            Gallery
          </h1>
        </div>
      </div>
      {/* about */}
      <div
        className='lg:grid lg:grid-cols-3 grid gap-0 sm:py-[40px] lg:py-[50px] lg:px-[5%] sm:px-[20px] bg-[#F8F8F8] mb-[50px]'>
        <Image.PreviewGroup
          preview={{
            onChange: (current, prev) => { },
          }}
        >
          {
            gallery.images.map((_img, index) => {
              return (
                <div key={`images-${index}`} className='w-[100%] lg:h-[300px] md:h-[200px]'>
                  <Image
                    className=''
                    // height={'320px'}
                    // width={'33%'}
                    src={_img.image}
                    style={{ padding: '0  20px 10px 0px', objectFit: 'cover' }} />
                </div>
              )
            })
          }
        </Image.PreviewGroup>
      </div>

      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

Gallery.propTypes = {
  loading: PropTypes.bool,
  onGetStaff: PropTypes.func,
  staff: PropTypes.array,
  selectedStaff: PropTypes.object
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading, staff, selectedStaff }
}) => ({
  stories, selectedStory, loading, staff, selectedStaff
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onSetSelectedStaff: payload => dispatch(setSelectedStaff(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Gallery);
