/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { IoIosArrowDown } from 'react-icons/io';
import PropTypes from 'prop-types'
import ceoImg from '../../assets/images/ceo.jpeg';
import OurCompanies from '../../components/ourCompanies/OurCompanies';
import './About.css';
import '../../components/sharedStyle.css';
import { connect } from 'react-redux';
import { getStaff, setSelectedStaff } from '../../store/app/actions';
import { Editor, EditorState, convertFromRaw } from 'draft-js';


function Staff({
  selectedStaff
}) {

  const [staff, setStaff] = useState({
    isPortfolioRaw: true,
    es: null
  })

  const convertContentToEditorState = (content) => {
    if (content) {
      try {
        const es = EditorState.createWithContent(
          convertFromRaw(JSON.parse(content))
        )
        setStaff({ ...staff, isPortfolioRaw: false, es })
      } catch {
        setStaff({ ...staff, isPortfolioRaw: true })
      }
    } else {
    }
  }


  useEffect(() => {
    window.scrollTo(0, 0)
    if (selectedStaff !== null) convertContentToEditorState(selectedStaff.portifolio)
  }, [])

  return (
    <div className="">
      {/* banner  */}
      <div className="w-full lg:h-[25vh] md:h-[25vh] sm:h-[20vh] bg-no-repeat bg-cover"
        style={{
          backgroundColor: '#88000F'
        }}
      >
        <div className={'grid sm:mx-[50px] lg:mx-[13%] xl:mx-[13%] 2xl:mx-[13%] lg:pt-[10vh] sm:pt-[60px] md:pt-[80px]'}>
          <h1 className={'sm:text-[16px] lg:text-[40px] font-500 text-white top-transparent-image-foreground-title top[-10px]'}>
            Management Team
          </h1>
        </div>
      </div>

      {
        !selectedStaff && (
          <div className="lg:py-[80px] md:py-[50px] sm:py-[40px] lg:px-[15%] sm:px-[5%] bg-[#F8F8F8] mb-[100px]">
            <div
              className='grid lg:flex gap-6 sm:height-[400px] lg:height-[400px] bg-[#D9D9D9] lg:rounded-[15px] sm:rounded-[15px]'>
              <img
                src={ceoImg}
                className='img-object-fit-cover sm:h-[400px] lg:h-[400px] sm:w-full lg:w-[500px] lg:rounded-tl-[15px] lg:rounded-bl-[15px] sm:rounded-[0] sm:rounded-tl-[15px]'
              />
              <div className='lg:h-full sm:h-[auto] justify-center lg:pl-[50px] md:pl-[20px] sm:pl-[20px] lg:pr-[150px] sm:pr-[10px] lg:py-[50px] text-[#2D2F2D]'>
                <h1 className='text-[26px]' style={{ fontWeight: '600' }}>{' Eng. Ridhuan Mringo'}</h1>
                <p className={'text-[20px]'} style={{ fontWeight: '400' }}>{'Chairman'}</p>
                <p className={'mt-[5px] text-[#88000F]'}>{'Derm Group (T) LTD'}</p>
                <p className={'mt-[30px] text-[18px] sm:pb-[20px]'}>

                  {
                    `
                    Eng. Ridhuan A. Mringo is a distinguished figure known for his dual roles as an accomplished electrical engineer and a visionary entrepreneur. 
                    `
                  }
                </p>
              </div>
            </div>
            <h1 className=' text-[36px] text-[#2D2F2D] mb-[10px] sm:mt-[40px] md:mt-[50px] lg:mt-[50px]' style={{ fontWeight: '700' }}>About</h1>
            <div className='text-[20px] text-[#2D2F2D] '>
              <p className="text-lg">
                {
                  `
As the Founder and Chairman of Derm Group, he has transformed the company from its beginnings as Derm Electrics (T) Ltd in 1998 into a diversified conglomerate.
With four dynamic subsidiaries—Derm Elevators Tanzania Ltd, Skyline Properties Ltd, Coast Concrete Poles Ltd.
Derm Group has established itself as a leader in various sectors, including real estate, civil and building construction,
Otis lift distributorship, and concrete poles manufacturing. He has also associated with other partners to establish SPEK LTD and SPEK industries LTD                  `
                }<br /><br />

                {
                  `
Eng. Mringo's deep passion for the financial sector led to his investment in Mwanga Hakika Bank,
 where he serves as Chairman and Derm Group Invested Major shares on it.  His strong business acumen has guided the company into several ventures,
  ensuring its ongoing success by expanding into increasingly technical and challenging fields.
                `
                }
              </p><br />
              <p className="text-lg">
                {
                  `
Eng. Mringo's career began as a Senior Project Engineer at Energy Services Limited, where he oversaw critical electrical installation projects,
from design to commissioning. With a Bachelor of Science in Electrical Engineering with honors from the University of Dar Es Salaam,
his expertise has been instrumental in driving Tanzania's infrastructure development.
                  `
                }
              </p>
              <p className="text-lg">
                {
                  `
Under his visionary leadership, Derm Group has thrived both domestically and internationally. 
Eng. Mringo is committed to developing talent, upholding quality, and maintaining the company's reputation for reliability. 
He is proud to lead a skilled and dedicated team, ensuring that Derm Group remains at the forefront of 
innovation and excellence in an increasingly competitive world.
                  `
                }
              </p>
            </div>
          </div>
        )
      }

      {
        selectedStaff && (
          <div className="lg:py-[80px] md:py-[50px] sm:py-[40px] lg:px-[15%] sm:px-[5%] bg-[#F8F8F8] mb-[100px]">
            <div
              className='grid lg:flex gap-6 sm:h-[400px] lg:h-[400px] bg-[#D9D9D9] lg:rounded-[15px] sm:rounded-[15px]'>
              <img
                src={selectedStaff.profile}
                className='img-object-fit-cover sm:h-[400px] lg:h-[400px] sm:w-full lg:w-[600px] lg:rounded-tl-[15px] lg:rounded-bl-[15px] sm:rounded-[0] sm:rounded-tl-[15px]'
              />
              <div className='lg:h-full sm:h-[auto] justify-center lg:pl-[50px] md:pl-[20px] sm:pl-[20px] lg:pr-[150px] sm:pr-[10px] lg:py-[50px] text-[#2D2F2D]'>
                <h1 className='text-[26px]' style={{ fontWeight: '600' }}>{selectedStaff.name}</h1>
                <p className={'text-[20px]'} style={{ fontWeight: '400' }}>{selectedStaff.position}</p>
                <p className={'mt-[5px] text-[#88000F]'}>{selectedStaff.company}</p>
                <p className={'mt-[30px] text-[18px] sm:pb-[20px]'}>{selectedStaff.about}</p>
              </div>
            </div>
            <h1 className=' text-[36px] text-[#2D2F2D] mb-[10px] sm:mt-[40px] md:mt-[50px] lg:mt-[50px]' style={{ fontWeight: '700' }}>About</h1>
            <div className='text-[20px] text-[#2D2F2D] '>
              {
                !staff.isPortfolioRaw && <Editor readOnly={true}
                  editorState={staff.es} />
              }
              {
                staff.isPortfolioRaw && <pre className='w-[100%] whitespace-pre-wrap font-sans'>{selectedStaff?.portifolio}</pre>
              }
            </div>
          </div>
        )
      }

      {/* our companies */}
      <OurCompanies />
    </div>
  );
}

Staff.propTypes = {
  loading: PropTypes.bool,
  onGetStaff: PropTypes.func,
  staff: PropTypes.array,
  selectedStaff: PropTypes.object
}

const mapStateToProps = ({
  app: { stories, selectedStory, loading, staff, selectedStaff }
}) => ({
  stories, selectedStory, loading, staff, selectedStaff
})

const mapDispatchToProps = dispatch => ({
  onGetStaff: () => dispatch(getStaff()),
  onSetSelectedStaff: payload => dispatch(setSelectedStaff(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(Staff);
